'use strict';

angular.module('webPartnerPortalApp')
  .config(function ($routeProvider) {
    $routeProvider
      .when('/login', {
        templateUrl: 'app/login/login.html',
        controller: 'LoginCtrl',
        controllerAs : 'LoginCtrlVM'
      });
  });
